<template>
  <v-container class="linerp-layout py-0">
    <v-row class="align-center">
      <v-col>
        <v-switch v-model="formData.is_spec" class="pt-3" dense color="green" inset></v-switch>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn color="primary" small @click="handleDialogSetSpecSwitch(true)">設定規格</v-btn>
      </v-col>
    </v-row>
    <DialogSetSpec
      :showDialogSetSpec="showDialogSetSpec"
      :handleDialogSetSpecSwitch="handleDialogSetSpecSwitch"
      :handleDialogSetSpecNextStep="handleDialogSetSpecNextStep"
      :specs="formData.spec"
    />
    <DialogSetPrice
      :showDialogSetPrice="showDialogSetPrice"
      :handleDialogSetPriceSwitch="handleDialogSetPriceSwitch"
      :handleDialogSetPriceFinish="handleDialogSetPriceFinish"
      :specs="formData.spec"
      :spec_price="formData.spec_price"
      :generate_spec_price="formData.generate_spec_price"
      :calculateResult="calculateResult"
      @updateSpecPrice="updateSpecPrice"
    />
  </v-container>
</template>

<script>
import DialogSetSpec from "./DialogSetSpec.vue";
import DialogSetPrice from "./DialogSetPrice.vue";
import formItemMixin from '@/components/form/mixins/formItemMixin'

export default {
  components: {
    DialogSetSpec,
    DialogSetPrice,
  },
  mixins: [formItemMixin],
  data() {
    return {
      model: true,
      showDialogSetSpec: false,
      showDialogSetPrice: false,
    };
  },
  computed: {
    calculateResult() {
      if (!this.formData.price) return null
      if (!this.formData.per_shipping_count) return null
      return this.formData.per_shipping_count * this.formData.price
    },
  },
  methods: {
    handleDialogSetSpecSwitch(status) {
      this.showDialogSetSpec = status
    },
    async handleDialogSetSpecNextStep() {
      const level1Specs = this.formData.spec.find(item => item.level === 1)
      const level2Specs = this.formData.spec.find(item => item.level === 2)

      if ( // 檢查是否 有填寫規格名稱 但沒有填寫規格標籤 或 全都沒填
        level1Specs?.tags?.length === 0 && level2Specs?.tags?.length === 0 ||
        level1Specs?.level_name && level1Specs?.tags?.length === 0 ||
        level2Specs?.level_name && level2Specs?.tags?.length === 0
      ) {
        this.$snotify.error("請新增規格標籤")
        return
      }
      const allTags = this.formData.spec.flatMap(item => item.tags)
      const tagNames = allTags.map(tag => tag.name)
      const uniqueTagNames = new Set(tagNames)

      if (uniqueTagNames.size !== tagNames.length) {
        this.$snotify.error("規格標籤名稱不能重複")
        return
      }
      if (level2Specs?.tags?.length && !level1Specs?.tags?.length) { // 檢查是否規格二 有填寫規格標籤 但沒有填寫規格一 則把二變成一
        const level1Specs = window.eagleLodash.cloneDeep(this.formData.spec.find(item => item.level === 1))
        const level2Specs = window.eagleLodash.cloneDeep(this.formData.spec.find(item => item.level === 2))
        this.formData.spec = [level2Specs, level1Specs].map((v, i) => {
          v.level = i + 1
          return v
        })
      }
      if (level1Specs?.tags?.length > 0 && !level1Specs?.level_name) { // 檢查是否規格一 有填寫規格標籤 但沒有填寫規格名稱 
        this.formData.spec.find(item => item.level === 1).level_name = '規格一'
      }
      if (level2Specs?.tags?.length > 0 && !level2Specs?.level_name) { // 檢查是否規格二 有填寫規格標籤 但沒有填寫規格名稱 
        this.formData.spec.find(item => item.level === 2).level_name = '規格二'
      }
      this.showDialogSetPrice = true
    },
    handleDialogSetPriceSwitch(status) {
      this.showDialogSetPrice = status
    },
    handleDialogSetPriceFinish() {
      this.showDialogSetSpec = false
      this.showDialogSetPrice = false
    },
    updateSpecPrice(generate_spec_price){
      this.formData.generate_spec_price = generate_spec_price
    }
  },
};
</script>

<style scoped>
h1 {
  color: #42b983;
}
</style>
