<template>
  <v-dialog
    v-model="showDialogSetSpec" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card class="linerp-layout">
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="設定規格"/>
        <v-btn @click="handleDialogSetSpecSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container v-for="(specV, specI) in specs" :key="specI">
        <v-row class="d-flex align-baseline">
          <v-col cols="3" class="py-0">
            <div>規格{{ specV.level }}</div>
          </v-col>
          <v-col cols="9" class="py-0 pl-0">
            <v-text-field
              dense
              outlined
              maxlength="10"
              counter
              :placeholder="specV.level_placeholder"
              v-model="specV.level_name"
              rounded
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 d-flex flex-wrap align-center">
            <div class="tag_input" v-for="(tagV, tagI) in specV.tags" :key="tagI">
              <v-text-field
                dense
                outlined
                maxlength="10"
                counter
                placeholder="請輸入"
                hide-details
                class="mr-2 mb-2"
                v-model="tagV.name"
                rounded
              >
                <template v-slot:append>
                  <v-icon @click="removeTag(specV.tags, tagI)" class="cursor-pointer mt-0">
                    ri-close-line
                  </v-icon>
                </template>
              </v-text-field>
            </div>
            <v-chip
              v-if="specV.tags.length < 10"
              color="primary"
              class="mr-2 mb-2"
              rounded
              @click="addTag(specV.tags)"
            >
              <template v-slot:default>
                <v-icon class="cursor-pointer mr-2">
                  ri-add-line
                </v-icon>
                <span>新增</span>
              </template>
            </v-chip>
          </v-col>
        </v-row>
        <v-row v-if="specI === 0">
          <v-col cols="12">
            <h3 class="font-weight-bold">為每個規格增加圖片</h3>
            <!-- <span>開啟此功能後，每個規格都需要上傳一張圖片</span>   -->
          </v-col>
          <!-- <v-col cols="2">
            <v-switch v-model="specV.isShowImg" class="pt-3" dense color="green" inset></v-switch>
          </v-col> -->
          <v-col v-if="specV.isShowImg" class="d-flex mb-3" style="overflow-x: auto;">
            <v-card
              v-for="(tagV, tagI) in specV.tags"
              :key="tagI"
              class="mr-3 mb-3 w-20"
              min-width="100px"
            >
              <v-img v-if="tagV.image" :src="tagV.image" height="100px" cover></v-img>
              <div v-else style="height: 100px; position: relative;" @click="clickAddSkuPhoto(`specInvisiblePhotoUploader${tagI}`)">
                <label for="photo-upload-btn" class="custom-file-upload d-block mb-2">
                  <v-btn
                    color="white"
                    small
                    style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);"
                  ><v-icon>fa fa-plus</v-icon></v-btn>
                  <invisible-photo-uploader
                    :ref="`specInvisiblePhotoUploader${tagI}`"
                    @onLoadingChange="onLoadingChange"
                    @appendPhoto="appendPhoto($event, tagV)"
                    type="default"
                  ></invisible-photo-uploader>
                </label>
                <v-btn
                  small
                  icon
                  class="mr-1"
                  style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);"
                >
                  <v-icon>fa fa-plus</v-icon>
                </v-btn>
              </div>
              <v-btn
                v-if="tagV.image"
                icon
                class="mr-1"
                style="position: absolute; right: -25px; top: -20px; z-index: 99;"
                @click="tagV.image = ''"
              >
                <i class="ri-close-circle-line" style="font-size: 20px;"></i>
              </v-btn>
              <v-spacer style="background-color: #ccc; height: 1px;"></v-spacer>

              <v-card-title class="py-1 px-2" style="min-height: 40px;">{{ tagV.name }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-btn color="primary" small width="100%" @click="handleDialogSetSpecNextStep">下一步，設定加價金額</v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialogSetSpec: {
      type: Boolean,
      required: true,
    },
    handleDialogSetSpecSwitch: {
      type: Function,
      required: true,
    },
    handleDialogSetSpecNextStep: {
      type: Function,
      required: true,
    },
    specs: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      skuPhotoUploadLoading: false,
    }
  },
  methods: {
    removeTag(list, tag){
      if(!Array.isArray(list)) return
      this.$delete(list, tag)
    },
    addTag(list){
      list.push(this.$eagleLodash.cloneDeep({name: '', image: ''}))
    },
    clickAddSkuPhoto(key) {
      this.$refs[key][0].openFileBrowser()
    },
    onLoadingChange(status) {
      this.skuPhotoUploadLoading = status
    },
    
    async appendPhoto(newPhoto, tag) {
      this.$set(tag, 'image', newPhoto.size_list.origin.url)
      // if(!Array.isArray(this.photo)) {
      //   this.initMultiPhoto()
      //   await this.$nextTick()
      // }
      // let photo = window.eagleLodash.cloneDeep(this.photo)
      // photo.push(newPhoto)
      // // photo.pop()
      // this.photo = photo
    },
  },
};
</script>
<style lang="sass" scoped>
.tag_input
  max-width: 125px
  ::v-deep .v-input
    &__slot
      padding-right: 15px !important
      padding-left: 15px !important
    &__append-inner
      margin-top: 0 !important
</style>